<template>
    <div class="news">
        <el-tabs type="border-card">
            <el-tab-pane>
                <span slot="label"><i class="el-icon-date"></i> 公司新闻</span>
                <el-row class="row-p">
                    <el-col :span="24">
                        <p>
                            2022年11月，龙祺（山东）数字科技有限公司顺利通过ISO20000和ISO27001认证！
                            ISO20000 是世界上第一部针对信息技术服务管理(IT Service Management)领域的国际标准，
                            ISO20000信息技术服务管理体系标准代表了被广泛认可的评估IT服务管理流程的原则的基础；
                            企业通过ISO20000认证可以提升客户满意度，通过提高IT服务本身的可用性、
                            可靠性以及安全性；持续不断地对服务流程进行优化，提升服务水平，保证项目能够如期交付，
                            从而为客户提供更加高质量的服务，让客户的满意度提升，更好地服务客户；提高企业竞争实力，
                            获得业内普遍认同的国际ISO20000认证，也就是向国际标杆靠齐，增强市场竞争力，
                            提高组织声誉，从而能够在总体上提高组织或者企业IT投资的报酬率；管控风险，
                            帮助企业更好地管理成本，明确企业业务战略目标的结合点，
                            通过诸多管理手段把现有的IT服务结构和资源配置整合并且完善，从而降低IT运营的管理成本和风险，
                            还能够将公司管理体系和业务流程整合起来，降低由于人员变动引起的各种风险。
                        </p>
                    </el-col>
                </el-row>
                <el-row class="row-p">
                    <el-col :span="24">
                        <p>
                            ISO27001是信息安全管理体系认证，是由国际标准化组织（ISO）采纳英国标准协会BS7799-2标准后实施的管理体系，
                            成为了“信息安全管理”的国际通用语言，企业建立ISO27001体系能有效保证企业在信息安全领域的可靠性，
                            降低企业泄密风险，更好的保存核心数据和重要信息。
                            通过进行ISO27001信息安全管理体系认证，可以增进组织间电子电子商务往来的信用度，
                            能够建立起网站和贸易伙伴之间的互相信任，随着组织间的电子交流的增加通过信息安全管理的记录可以看到信息安全管理明显的利益，
                            并为广大用户和服务提供商提供一个基础的设备管理。同时，把组织的干扰因素降到最小，创造更大收益。
                        </p>
                    </el-col>
                </el-row>
                <el-row class="row-img">
                    <el-col :span="8">
                        <img src="../assets/img/icon6.jpg"/>
                    </el-col>
                    <el-col :span="8">
                        <img src="../assets/img/icon7.jpg"/>
                    </el-col>
                    <el-col :span="8">
                        <img src="../assets/img/icon13.jpg"/>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane class="pane-box">
                <span slot="label"><i class="el-icon-date"></i> 行业新闻</span>
                <h1>济南正全面推进智慧城市建设</h1>
                <el-row class="row-box">
                    <el-col :span="24">
                        <p class="text-p">
                            马路上随处可见的出租车可以对道路PM2.5、PM10实时移动监测；
                            集成服务事项有308项可通过网上办理……近年来，济南着力建设高效和谐的善治之城，
                            居民生活更便利、社会服务更高效，由济南市大数据局打造的多个智能平台，让济南不仅善治还善“智”。
                        </p>
                    </el-col>
                </el-row>
                <el-row class="row-box">
                    <el-col :span="24">
                        <p class="bt-p">政务办理、医保查询</p>
                        <p class="bt-p">惠民智慧平台事项办理更方便</p>
                        <p class="text-p">
                            自2017年济南加快建设新型智慧城市以来，
                            济南变得更方便了——市民可通过“一件事”服务专区网办事项达到308项；
                            第一批电子证照证明“用证”事项实施清单公布后，实现330类电子证照同步制发，
                            4400余个政务服务事项证照证明免提交办理；“保医通”服务平台与11家主流保险机构的40余款商业医保产品实现无纸化、
                            线上化快速结算，目前，已累计办理商业保险理赔业务37万笔，最快赔付时间为2分钟
                        </p>
                        <div class="img1">
                            <img src="../assets/img/icon11.png"/>
                        </div>
                        <p class="text-p">
                            惠民智慧平台已经融入了市民的生活，
                            其中政务服务移动端上线应用服务已达1950余项；
                            “泉城链政务数据可信共享新模式”已在金融服务、司法公证、政务服务、
                            社会应用等领域落地应用，并且在省会城市经济圈（黄河流域）等省内14个城市复制推广；
                            “政保通”数据服务平台已接入机动车登记、交通失信人员、死亡人口登记等五类数据，
                            可实现机动车辆保险等商业险种快速理赔。
                        </p>
                    </el-col>
                </el-row>
                <el-row class="row-box">
                    <el-col :span="24">
                        <p class="bt-p">多平台打造新型智慧城市</p>
                        <p class="bt-p">善治之城更是善“智”之城</p>
                        <p class="text-p">
                            当前，济南正在“十大之城”建设中，着力打造高效和谐的善治之城，由济南市大数据
                            局提供的多种技术支持，正在为其提供助力。其中，会同发改部门打造宏观经济数据平台，
                            建立宏观经济数据平台，实现济南历年主要统计数据的归集整合、
                            查询分析以及同类城市的横向比对等功能。累计归集济南统计数据32万个、
                            归集数据库建设单位专项数据库数据300万个。凭借数据平台功能，
                            协助各区县（功能区）梳理其三年来固定资产投资增速走势，查摆出隐忧隐患，作出提前预警、指导提前应对。
                        </p>
                        <div class="img1">
                            <img src="../assets/img/icon12.png"/>
                        </div>
                        <p class="text-p">
                            建设济南统一的智慧社区综合服务管理平台，打造了1个基层治理数据库，
                            1个智慧社区综合服务管理平台和智慧党建、便民服务、
                            居民自治等智慧社区服务场景，同时，建设城市运行管理服务平台，
                            目前该平台接入了智慧环卫、数字城管、智慧执法等20余个信息化系统，
                            通过该平台，使多个职能部门可以协同合作，增强城市管理工作的实时监测、动态分析、
                            统筹协调、指挥调度和综合评价能力，提高城市运行效率。
                        </p>
                        <p class="text-p">
                            济南正全面推进智慧城市建设，济南市大数据局围绕惠民、
                            经济运行、智慧社区、城市管理、环境监测和城市防汛等多方面推出相应的“智慧”平台，
                            助力善治之城崛起，让济南不仅善治还善“智”。
                        </p>
                    </el-col>
                </el-row>
                
            </el-tab-pane>
        </el-tabs>


    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.news{
    width: 100%;
    height: 100%;
}

.row-img img{
    width:400px;
    height:500px;
    display: block;
    margin: 0 auto;
    background-size:100% 100%;
}
// .row-img img:hover{
//     transform: scale(1.25);
//     box-shadow: 0 25px 40px raba(0,0,0,5);
// }
.row-p p{
    font-size: 1.3rem;
    color: #878787;
    text-indent: 40px;

}

.pane-box>h1{
    text-align: center;
}
.pane-box p{
    width: 60%;
    margin: 0 auto;
}
.pane-box .bt-p{
    font-size: 20px;
    font-weight: 600;
}
.row-box .img1{
    width:700px;
    margin: 0 auto;
    background-size: 100% 100%;
}
.row-box .text-p{
    text-indent:40px;
    font-size: 18px;
}

</style>